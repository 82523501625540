import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import awsRegions from "aws-regions";

import {
  postFeatureConfiguration,
} from "../../../../store/featureConfiguration/featureConfigurationSlice";

const BucketPolicyModal = (props) => {
  const [role, setRole] = useState("");
  const [bucketName, setBucketName] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [errors, setErrors] = useState({});
  const { accountNumber, externalId, iamrole } = props.editAccount;
  const { addOnsName } = props;
  const setNotificationSent = props.setNotificationSent;

  const validateRole = (value) => {
    if (!value) {
      return "Role is required";
    } else if (!/^arn:aws:iam::[0-9]{12}:role/.test(value)) {
      return "IAM Role must be in proper format";
    }
    return "";
  };

  const validateAccountNumber = () => {
    const value = accountNumber;
    if (!value) {
      return "Account number is required";
    } else if (!/^[0-9]+$/.test(value) || value.length !== 12) {
      return "Account number must be exactly 12 digits and only numbers";
    }
    return "";
  };

  const validateBucketName = (value) => {
    if (!value) {
      return "Bucket name is required";
    } else if (!/^arn:aws:s3:::[a-z0-9]([a-z0-9.-]{1,61}[a-z0-9])?$/.test(value)) {
      return "S3 bucket name must be in proper format";
    }
    return "";
  };

  const validateSelectedRegion = (value) => {
    if (!value) {
      return "AWS Region is required";
    }
    return "";
  };

  const handleRoleChange = (e) => {
    const value = e.target.value;
    setRole(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      role: validateRole(value),
    }));
  };

  const handleBucketNameChange = (e) => {
    const value = e.target.value;
    setBucketName(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      bucketName: validateBucketName(value),
    }));
  };

  const handleRegionChange = (e) => {
    const value = e.target.value;
    setSelectedRegion(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      selectedRegion: validateSelectedRegion(value),
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setNotificationSent(false);

    const roleError = validateRole(role);
    const accountNumberError = validateAccountNumber();
    const bucketNameError = validateBucketName(bucketName);
    const selectedRegionError = validateSelectedRegion(selectedRegion);

    if (!roleError && !accountNumberError && !bucketNameError && !selectedRegionError) {
      const featureConfigurationRequest = {
        role: role,
        accountNumber: accountNumber,
        bucketName: bucketName,
        readOnlyRole: iamrole,
        selectedRegion: selectedRegion,
        module: addOnsName,
        externalId: externalId,
      }
      console.log("featureConfigurationRequest = ", featureConfigurationRequest);
      props.postFeatureConfiguration(featureConfigurationRequest);
    } else {
      setErrors({
        role: roleError,
        accountNumber: accountNumberError,
        bucketName: bucketNameError,
        selectedRegion: selectedRegionError,
      });
    }
  };

  return (
    <React.Fragment>
      <Modal isOpen={props.isOpen} backdrop="static" centered style={{ maxWidth: "500px" }}>
        <ModalHeader toggle={props.closeModal}>
          Verify Bucket Policy
        </ModalHeader>
        <ModalBody>
          Verify Bucket Policy for Additional Addons: {addOnsName} for Account Number: {accountNumber}

          <Box>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Role ARN*"
                size="small"
                value={role}
                onChange={handleRoleChange}
                error={!!errors.role}
                helperText={errors.role}
                fullWidth
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Account Number*"
                size="small"
                value={accountNumber}
                disabled
                error={!!errors.accountNumber}
                helperText={errors.accountNumber}
                fullWidth
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="S3 Bucket ARN*"
                size="small"
                value={bucketName}
                onChange={handleBucketNameChange}
                error={!!errors.bucketName}
                helperText={errors.bucketName}
                fullWidth
              />
            </FormControl>
            <FormControl fullWidth margin="normal" error={!!errors.selectedRegion}>
              <InputLabel>S3 AWS Region*</InputLabel>
              <Select
                value={selectedRegion}
                onChange={handleRegionChange}
                label="S3 AWS Region*"
                size="small"
              >
                <MenuItem value="">
                  <em>Select AWS Region*</em>
                </MenuItem>
                {awsRegions.list().map((region) => (
                  <MenuItem key={region.code} value={region.code}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.selectedRegion}</FormHelperText>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3, backgroundColor: "#F98125", "&:hover": { backgroundColor: "#e27021" } }}
              onClick={handleSubmit}
              disabled={props.isPostingFeatureConfiguration}
            >
              Submit
            </Button>
          </Box>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};


const mapStateToProps = (state) => {
  const { 
    isPostingFeatureConfiguration,
    postFeatureConfigurationMessage,
    postFeatureConfigurationError,
  } = state.featureConfiguration;
  return {
    isPostingFeatureConfiguration,
    postFeatureConfigurationMessage,
    postFeatureConfigurationError,    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postFeatureConfiguration: (featureConfigurationPayload) => {
      dispatch(postFeatureConfiguration(featureConfigurationPayload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BucketPolicyModal);