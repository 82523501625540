import React from "react";

import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./store";
import { OrgProvider } from "./OrgContext";

if (process.env.REACT_APP_ENVIRONMENT === "Trial") {
  console.log = console.warn = console.error = () => {};
}

const app = (
  <Provider store={store}>
    <OrgProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </OrgProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
