import React from "react";
import { Route, Redirect } from "react-router-dom";

import accessToken from "../helpers/jwt-token-access/accessToken";
import jwtDecode from "jwt-decode";

import DirtyRedirectCheckHoc from "./dirtyRedirectCheckHoc";
import AppLogout from "../components/AppLogout";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  allowedRoles,
  message,
  licenseName,
  hasSelectedOrganization,
  ...rest
}) => {
  console.log("🚀 ~ hasSelectedOrganization:", hasSelectedOrganization)
  console.log("AppRoute ...rest = ", JSON.stringify(rest));

  return (
    <Route
      {...rest}
      render={(props) => {
        const token = accessToken();
        let userRole = "";
        console.log("is token Present :: ", token);

        const isAuthenticated = !!localStorage.getItem("authUser");

        if (token) {
          const decoded = jwtDecode(token);
          userRole = decoded["cognito:groups"][0];
          console.log("Token is present, userRole = ", userRole);
        } else {
          console.log("Token is not present, userRole = ", "");
        }
        console.log(
          "In Route, allowedRoles :: ",
          allowedRoles,
          " User :: ",
          userRole
        );

        if (isAuthProtected && !localStorage.getItem("authUser")) {
          console.log("Token is not found, redirecting to /login");
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        if (!isAuthProtected) {
          console.log("Rendering unprotected route");
          return (
            <Layout>
              <DirtyRedirectCheckHoc>
                <Component {...props} message={message} />
              </DirtyRedirectCheckHoc>
            </Layout>
          );
        }

        if(isAuthProtected && isAuthenticated && !hasSelectedOrganization && props.location.pathname !== "/orgSelection") {
          console.log("🚀 ~ hasSelectedOrganization:", hasSelectedOrganization)
          return(
            <Redirect
              to={{ pathname: "/orgSelection", state: { from: props.location } }}
            />
          )
        }

        if (allowedRoles.includes(userRole)) {
          console.log("In Route,", allowedRoles, " includes ", userRole);
          return (
            <Layout>
              <DirtyRedirectCheckHoc>
                <AppLogout>
                  <Component
                    {...props}
                    message={message}
                    licenseName={licenseName}
                  />
                </AppLogout>
              </DirtyRedirectCheckHoc>
            </Layout>
          );
        } else {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
};

export default AppRoute;
