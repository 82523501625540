const getOrgCode = () => {
  try {
    const obj = JSON.parse(localStorage.getItem("selectedOrganization"));
    return obj?.orgCode || "";
  } catch (error) {
    console.error(
      "Error parsing selectedOrganization from localStorage:",
      error
    );
    return "";
  }
};

export default getOrgCode;

