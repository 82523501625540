import { useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { debounce } from 'lodash';
import { getListOfOrgsAndAccts } from "../../../store/actions";
import { fetchKibanaDashboards } from "../../../store/kibanaDashboards/kibanaDashboardsSlice";

// Custom hook to fetch and return accounts
export const useAccounts = () => {
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.orgAccount.accounts);
  const fetchFailed = useSelector((state) => state.orgAccount.fetchFailed);
  console.log("🚀 ~ useOrganizations ~ fetchFailed:", fetchFailed)
  const accountsLoading = useSelector((state) => state.orgAccount.loading);

  const isInitialLoad = useMemo(() => !accounts || accounts.length === 0, [accounts]);

  // Create a debounced version of the dispatch function
  const debouncedFetchAccounts = debounce(() => {
    console.log("🚀 ~ useAccounts ~ debouncedFetchAccounts getListOfOrgsAndAccts():");
    dispatch(getListOfOrgsAndAccts());
  }, 300); 

  useEffect(() => {
    if (isInitialLoad && !accountsLoading && !fetchFailed) {
      console.log("🚀 ~ useAccounts ~ getListOfOrgsAndAccts():");
      debouncedFetchAccounts();
    }
    return () => {
      debouncedFetchAccounts.cancel();
    };
  }, [isInitialLoad, accountsLoading, debouncedFetchAccounts]);

  return accounts;
};

export const useOrganizations = () => {
  const dispatch = useDispatch();
  const organizations = useSelector((state) => state.orgAccount.organizations);
  const fetchFailed = useSelector((state) => state.orgAccount.fetchFailed);
  console.log("🚀 ~ useOrganizations ~ fetchFailed:", fetchFailed)

  const debouncedFetchOrganizations = debounce(() => {
    console.log("🚀 ~ useOrganizations ~ debouncedFetchOrganizations getListOfOrgsAndAccts():");
    dispatch(getListOfOrgsAndAccts());
  }, 300);

  useEffect(() => {
    if ((!organizations || organizations.length === 0) && !fetchFailed) {
      console.log("🚀 ~ useOrganizations ~ getListOfOrgsAndAccts():");
      debouncedFetchOrganizations();
    }

    return () => {
      debouncedFetchOrganizations.cancel();
    };
  }, [organizations, debouncedFetchOrganizations, dispatch]);

  return organizations;
};

// Get Organizations
export const useOrganization = () => {
  const dispatch = useDispatch();
  const orgsLoading = useSelector((state) => state.orgAccount.loading);
  const fetchFailed = useSelector((state) => state.orgAccount.fetchFailed);
  console.log("🚀 ~ useOrganizations ~ fetchFailed:", fetchFailed)

  // Use shallowEqual to prevent unnecessary re-renders
  const organizations = useSelector(
    (state) => state.orgAccount.organizations,
    shallowEqual
  );

  // Memoize the return value
  const orgData = useMemo(() => {
    console.log("🚀 ~ useOrganization ~ organizations:", organizations);
    return organizations && organizations.length > 0 ? organizations[0] : null;
  }, [organizations]);

  const debouncedFetchOrgs = debounce(() => {
    console.log("🚀 ~ useOrganization ~ debounced getListOfOrgsAndAccts():");
    dispatch(getListOfOrgsAndAccts());
  }, 300);

  useEffect(() => {
    if (!organizations?.length && !orgsLoading && !fetchFailed) {
      console.log("🚀 ~ useOrganization ~ getListOfOrgsAndAccts():");
      debouncedFetchOrgs();
    }

    return () => {
      debouncedFetchOrgs.cancel(); // Cancel debounce on unmount
    };
  }, [organizations, orgsLoading, debouncedFetchOrgs]); // Keep only dispatch in dependency array

  return orgData;
};

// Get Kibana Dashboards URL
export const useFetchKibanaDashboards = () => {
  const organization = useOrganization();
  const orgcode = organization?.orgcode;

  const dispatch = useDispatch();
  const { dashboards, isLoading, errorMessage } = useSelector(
    (state) => state.kibanaDashboards
  );

  useEffect(() => {
    if (orgcode) {
      if ((!dashboards || dashboards.length === 0) && !errorMessage) {
        if (!isLoading) {
          console.log("🚀 ~ useFetchKibanaDashboards ~ fetchKibanaDashboards():");
          dispatch(fetchKibanaDashboards(orgcode));
        }
      }
      
    }
  }, [dispatch, isLoading, dashboards, orgcode]);

  return useMemo(
    () => ({ dashboards, isLoading, errorMessage }),
    [dashboards, isLoading, errorMessage]
  );
};