import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";
import refreshToken from "./jwt-token-access/refreshToken";
import jwt_decode from "jwt-decode";

import * as url from "./url_helper";
import getOrgCode from "./jwt-token-access/getOrgCode";

const AWS_API_URL = process.env.REACT_APP_AWS_API_URL;

const axiosAwsApi = axios.create({
  baseURL: AWS_API_URL,
  headers: {
    "Content-Type": "application/json",
    //Added the following header
    "Access-Control-Allow-Origin": "*",
  },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const refreshInstance = axios.create({
  baseURL: AWS_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

refreshInstance.interceptors.response.use(
  (response) => {
    const token = accessToken();
    processQueue(null, token);
    return response;
  },
  (error) => {
    processQueue(error, null);
    return Promise.reject(error);
  }
);

axiosAwsApi.interceptors.request.use(
  async (config) => {
    console.log("AWS API Request Interceptor, Request intercept :: ", config);
    
    const token = accessToken();
    console.log("Adding token :: ", token);
    
    const orgCode = getOrgCode();
    
    let authUser = null;
    let username = null;
    if (token) {
      authUser = jwt_decode(token);
    }
    if (authUser) {
      username = authUser.sub.charAt(0) + authUser.sub.slice(1);
      
      console.log("aws_api_helper, Adding username :: ", username);
    }

    config.headers["Authorization"] = token ? `Bearer ${token}` : "";
    config.headers["username"] = username;
    config.headers["X-Cloud-Catcher-Scope"] = orgCode;
    
    if (config.data instanceof FormData) {
      delete config.headers["Content-Type"]; // Let Axios set it automatically
    } else {
      config.headers["Content-Type"] = "application/json"; // Set default for JSON
    }
    
    return config;
  },
  (error) => {
    console.log("AWS API Request Interceptor, error :: ", error);
    return Promise.reject(error);
  }
);

axiosAwsApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log("AWS API Response Interceptor during error, error :: ", error);
    try {
      const status = error.response?.status;
      const originalRequest = error.config;
      const refToken = refreshToken();
      const accToken = accessToken();

      let authUser = null;
      let username = null;
      if (accToken) {
        authUser = jwt_decode(accToken);
      }
      if (authUser) {
        username = authUser.sub.charAt(0) + authUser.sub.slice(1);
      }

      if (
        status === 401 &&
        !originalRequest._retry &&
        refToken &&
        refToken !== ""
      ) {
        console.log(">AWS API Response Interceptor, status = ", status);
        if (isRefreshing) {
          console.log(">AWS API Response Interceptor, isRefreshing ");
          try {
            const token = await new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            });

            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            console.log(
              ">AWS API Response Interceptor, Returning request with token "
            );
            return axiosAwsApi(originalRequest);
          } catch (error) {
            console.log(">AWS API Response Interceptor, isRefreshing > catch ");
            return Promise.reject(error);
          }
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          console.log(
            ">AWS API Response Interceptor, getting a new refresh token "
          );
          const response = await refreshInstance.post(url.POST_LAMBDA_REFRESH, {
            refreshToken: refToken,
            username: username,
          });

          let authUser = response.data;
          authUser.token = authUser.accessToken;
          localStorage.setItem("authUser", JSON.stringify(authUser));

          console.log(">AWS API Response Interceptor, refresh token retrieved");
          const token = accessToken();
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          console.log(
            ">AWS API Response Interceptor, Returning original request with new token"
          );
          return axiosAwsApi(originalRequest);
        } catch (error) {
          console.log(
            ">AWS API Response Interceptor, try, catch, error = ",
            error
          );
          processQueue(error, null);
          window.location.href = "/sessionExpired";
          return Promise.reject(error);
        } finally {
          isRefreshing = false;
        }
      }

      return Promise.reject(error);
    } catch (error) {
      console.log(">AWS API Response Interceptor, catch, error = ", error);
      return Promise.reject(error);
    }
  }
);

export async function get(url, config = {}) {
  return axiosAwsApi
    .get(url, { ...config })
    .then((response) => ({ ...response.data, status: response.status }))
    .catch((err) => err.response);
}

export async function getBodyOnly(url, config = {}) {
  return axiosAwsApi
    .get(url, { ...config })
    .then((response) => ({ ...response.data }))
    .catch((err) => err.response);
}

export async function post(url, data, config = {}) {
  console.log("Post AWS called with config: ", config);
  return axiosAwsApi
    .post(url, data, { ...config })
    .then((response) => response)
    .catch((err) => err.response);
  // .catch((error) => console.log("In post call :: ", error.response.status, "Error :: ", error.response.data ));
}

export async function put(url, data, config = {}) {
  console.log("Put AWS called with data: ", data, "url: ", url);
  return axiosAwsApi
    .put(url, { ...data }, { ...config })
    .then((response) => ({ ...response.data, status: response.status }))
    .catch((err) => err.response);
}

export async function del(url, config = {}) {
  console.log("Delete AWS called with url: ", url);
  return axiosAwsApi
    .delete(url, { ...config })
    .then((response) => ({ ...response.data, status: response.status }))
    .catch((err) => err.response);
}

export default axiosAwsApi;
