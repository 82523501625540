// *************** AWS Lambda API Calls ******************
//AWS Pricing Info
export const GET_LICENSE_PRICE_INFO = "/subscribe";
//AWS Metric data
export const GET_METRIC_INFO = "/metric";

//AWS Lambda URL
export const POST_LAMBDA_LOGIN = "/cloudnova/login";
export const POST_LAMBDA_LOGINWITHMFA = "/cloudnova/loginWithMFA";
export const POST_LAMBDA_LOGINVERIFYTOTP = "/cloudnova/verifyTotp";
export const POST_LAMBDA_REFRESH = "/cloudnova/refresh";
export const POST_LAMBDA_FORGET_PASSWORD = "/cloudnova/password/forgot";
export const POST_LAMBDA_RESET_FORGOT_PASSWORD = "/cloudnova/password/forgot/confirm";
export const PUT_RESET_PASSWORD = "/cloudnova/changePassword"  // Change Password
export const POST_LAMBDA_NEW_USER = "/cloudnova/user";
export const GET_LAMBDA_LIST_OF_ORGS_AND_ACCOUNTS = "/cloudnova/accounts";
export const GET_LAMBDA_PROFILE_ROLE_INFO = "/cloudnova/roles";
export const GET_LAMBDA_ORGS_NAME = "/cloudnova/organization/name";
export const POST_LAMBDA_REGISTER = "/organization/register";
export const PUT_LAMBDA_REGISTER = "/cloudnova/organization/updateOrgAddress"
export const GET_LAMBDA_ACC_RULES = "/cloudnova/accounts/rule";
export const GET_LAMBDA_ALL_RULES = "/cloudnova/rule";
export const GET_LAMBDA_LICENSING = "/cloudnova/licensing";
export const GET_LAMBDA_AWS_ACCOUNT_NUMBER = "/cloudnova/awsaccountnumber";
export const POST_LAMBDA_AWS_ACCOUNT_NUMBER = "/cloudnova/awsaccountnumber";
export const POST_LAMBDA_ORG_ACCOUNT = "/cloudnova/accounts";
export const PUT_LAMBDA_EDIT_USER = "/cloudnova/user";
export const DELETE_LAMBDA_USER = "/cloudnova/user";
export const PUT_LAMBDA_RULES_MAP = "/cloudnova/rule";
export const UPDATE_LAMBDA_ORG_ACCOUNT = "/cloudnova/accounts";
export const POST_REGISTER = "organization/register"
export const GET_ACCOUNT_ACTIVATION = "/userauth/resendactivate";
export const GET_REACTIVE_USER_LAMBDA = '/userauth/resendactivate';
export const PUT_VALIDATE_PROSPECTIVE_TOKEN = "/userauth/checkprospectivetoken";
export const GET_LAMBDA_RESOURCE_GROUPS = '/cloudnova/resourcegroups';
export const POST_LAMBDA_RESOURCE_GROUP = '/cloudnova/resourcegroups';
//Tableau
export const GET_TAB_TOKEN_TABLEAU_LAMBDA = "/cloudnova/tabtoken";

export const GET_RULE_REMEDIATION = "/cloudnova/ruleRemediation";

// IAC
export const GET_IAAC_METRICS = "/iacmanagementfromui/metrics";
export const GET_IAAC_FILE_HISTORY = "/iacmanagementfromui/info";
export const GET_IAAC_FILE = "/iacmanagementfromui/download";
export const GET_FILE_UPLOAD_URL = "/iacmanagementfromui/upload";
export const POST_FILE_UPLOAD = "/iacmanagementfromui/upload";
export const POST_FILE_COMMIT_DETAILS = "/iacmanagementfromui/info";
export const GET_IAAC_SUBSCRIPTION = "/cloudnova/iaacSubscription";
export const POST_IAAC_SUBSCRIPTION = "/cloudnova/iaacSubscription";
export const PUT_IAAC_SUBSCRIPTION = "/cloudnova/iaacSubscription";
export const GET_IAAC_LICENSE = "/cloudnova/iaacLicense";

// Release Notes
export const GET_RELEASE_NOTES = "cloudnova/documentation";
export const GET_RELEASE_DETAILS = "cloudnova/documentation";

// Support
export const POST_SUPPORT_EMAIL = "cloudnova/support";

export const POST_AWS_FULFILLMENT = "/aws-marketplace/register";
export const POST_AWS_ONBOARD = "/aws-marketplace/onboard";

//Enable Additional Adons/Features
export const POST_FEATURE_CONFIGURATION ="/cloudnova/feature-configuration";
export const POST_ACCOUNT_CONFIGURATION ="/cloudnova/account-configuration";

// Fetch Kibana Dashboards Url
export const FETCH_KIBANA_DASHBOARDS_URL = '/cloudnova/getdashboards';

// Fetch Billing Records
export const FETCH_BILLING_RECORDS_URL = '/cloudnova/billing';

// Tag Allocation for Cost Insight
export const COST_INSIGHT_TAG_ALLOCATION = '/cloudnova/tags';

// Get Organization scoping
export const GET_ORGANIZATION_SCOPE = '/cloudnova/scope';