import React, { useContext, useEffect } from "react";
import { Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import InjectAxiosInterceptors from "./helpers/InjectAxiosInterceptors";
import { adminRoutesOnly, authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// Import actions
import { getLicensingData } from "./store/actions";

// Layouts
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import styles
import "./datatables.scss";
import "./theme.scss";
import "./App.css";
import loadingGif from "./assets/images/aws/loadingGif.gif";
import { SnackbarProvider } from "notistack";
import { OrgContext } from "./OrgContext";

const App = (props) => {
  const { hasSelectedOrganization } = useContext(OrgContext);

  // useEffect(() => {
  //   if (!props.licenseData?.data && localStorage.getItem("authUser")) {
  //     props.getLicensingData();
  //   }
  // }, [props]);

  const Layout = VerticalLayout;
  const isLicenseLoading = props.licenseData?.loading;
  const isAuthenticated = !!localStorage.getItem("authUser");

  if (isLicenseLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <img src={loadingGif} height={40} alt="Loading Spinner" />
        <p>Please wait a while!</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Router>
        <InjectAxiosInterceptors />
        <SnackbarProvider />

        <Switch>
          {/* Public Routes */}
          {publicRoutes.map((route, idx) => (
            <AppRoute
              key={idx}
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              isAuthProtected={false}
              allowedRoles={["Administrator", "StandardUser", "", "PowerUser"]}
            />
          ))}

          {/* Admin Routes */}
          {adminRoutesOnly.map((route, idx) => (
            <AppRoute
              key={idx}
              path={route.path}
              layout={Layout}
              component={route.component}
              isAuthProtected={true}
              allowedRoles={["Administrator"]}
            />
          ))}

          {/* Auth Protected Routes */}
          {authProtectedRoutes.map((route, idx) => (
            <AppRoute
              key={idx}
              path={route.path}
              layout={route.path === "/orgSelection" ? NonAuthLayout : Layout}
              component={route.component}
              hasSelectedOrganization={hasSelectedOrganization}
              isAuthProtected={true}
              allowedRoles={["StandardUser", "Administrator", "PowerUser"]}
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  layout: state.layout,
  licenseData: state.licensing,
});

const mapDispatchToProps = {
  getLicensingData,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
