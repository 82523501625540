import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import InaccessibleResources from "../FreeTrial/InaccessibleResources";
import GetKibanaDashboard from "../../components/Common/HelperComponents/KibanaDashboardComponents/GetKibanaDashboard";

const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
const freeLicenses = [freeLicenseName];

const Security = ({ licenseName, message }) => {
  const [activeTab, setActiveTab] = useState("cspm");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "asset":
        if (freeLicenses.includes(licenseName)) {
          return <InaccessibleResources message={message} />;
        } else {
          return <GetKibanaDashboard dashboardKey={"Resource Dashboard"} />;
        }
      case "cspm":
        if (freeLicenses.includes(licenseName)) {
          return <InaccessibleResources message={message} />;
        } else {
          return <GetKibanaDashboard dashboardKey={"Security_2"} />;
        }
      case "trend":
        if (freeLicenses.includes(licenseName)) {
          return <InaccessibleResources message={message} />;
        } else {
          return <GetKibanaDashboard dashboardKey={"Security_Trend_Dashboard"} />;
        }
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Cloud Security Posture" value="cspm" />
        <Tab label="Asset Security Posture" value="asset" />
        <Tab label="Trend Analyzer" value="trend" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default Security;
