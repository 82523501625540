import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  checkLogin,
  clearStore,
  apiError,
  verifyTotpRequest,
  clearAllLoginMessages,
  getLicensingData,
} from "../../store/actions";
import logodark from "../../assets/images/Expanded_Colored_PoweredBy.png";
import duoQrCode from "../../assets/images/duo-qr-code.png";

import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
} from "@material-ui/core";

import { Container, Paper, Slide } from "@mui/material";
import { styled } from "@mui/material/styles";

import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";

import spinner from "./../../assets/images/aws/loadingGif.gif";
import { BackgroundPattern } from "../../components/BackgroundPattern";

import QrCode from "./QrCode";
import VerificationOtp from "./VerificationOtp";
import VerificationMfaCode from "./VerificationMfaCode";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

const LinkButton = styled(Button)({
  textTransform: "none",
});

const styles = (theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    position: "relative",
    overflow: "hidden",
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
    animation: "$animateBackground 10s infinite",
  },
  "@keyframes animateBackground": {
    "0%": {
      transform: "translateX(-100%)",
    },
    "100%": {
      transform: "translateX(100%)",
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: "rgba(0, 0, 0, 0)",
  },
  container: {
    position: "relative",
    zIndex: 1,
    justifyContent: "space-evenly",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  form: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[3],
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      showPassword: false,
      otpModalOpen: false,
      // otp: "",
      remainingAttempts: 3,
      redirecting: false,
      step: 1, // 1: Login, 2: MFA Setup, 3: OTP Verification
      base64Image: null,
      appDownloadModalOpen: false,
      // isResendDisabled: false,
      // resendSuccessMessage: "",
      // otpValidationMessage: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // Function to open OTP modal
  openOtpModal = () => {
    this.setState({ otpModalOpen: true });
  };

  // Function to close OTP modal
  closeOtpModal = () => {
    this.setState({
      otpModalOpen: false,
      remainingAttempts: 3,
    });
  };

  openAppDownloadModal = () => {
    this.setState({ appDownloadModalOpen: true });
  };

  closeAppDownloadModal = () => {
    this.setState({ appDownloadModalOpen: false });
  };

  setRemainingAttempts = (remainingAttempts) => {
    this.setState({ remainingAttempts: remainingAttempts });
  };

  componentDidMount() {
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  componentWillUnmount() {
    document.body.classList.remove("auth-body-bg");
  }

  componentDidUpdate(prevProps) {
    const {
      stage,
      user,
      orgLicensing,
      history,
      verifyTotpSuccess,
      verifyTotpError,
      loginError,
    } = this.props;

    // Handle OTP Modal Opening
    if (user && !this.state.otpModalOpen) {
      this.openOtpModal();
    }

    // Handle MFA Setup
    if (
      stage === "MFASETUP" &&
      this.props.mfaSetup &&
      !this.state.base64Image &&
      this.state.step !== 2
    ) {
      this.setState({
        base64Image: this.props.mfaSetup.base64Image,
        step: 2,
      });
    }

    // Handle Verification Stage
    if (stage === "VERIFICATION" && this.state.step !== 3) {
      this.setState({ step: 3 });
    }

    // Handle Authentication and Redirection
    if (stage === "AUTHENTICATED" && user) {
      history.push("/orgSelection");
    }

    // Handle Success Response for TOTP Verification
    if (
      verifyTotpSuccess &&
      prevProps.verifyTotpSuccess !== verifyTotpSuccess
    ) {
      enqueueSnackbar("Successfully set up MFA pairing. We are redirecting to the login page. Note: please wait till the authentication code refreshes before entering the code again.", {
        variant: "success",
      });
      this.setState({ redirecting: true });
      setTimeout(() => this.setState({ step: 1, redirecting: false }), 20000);
      this.props.clearAllLoginMessages();
    }

    // Handle Error Response for TOTP Verification
    if (verifyTotpError && prevProps.verifyTotpError !== verifyTotpError) {
      enqueueSnackbar(verifyTotpError, { variant: "error" });
      setTimeout(() => {
        this.props.clearAllLoginMessages();
      }, 20000);
    }

    // Handle Login Error
    if (loginError && prevProps.loginError !== loginError) {
      enqueueSnackbar(`Login failed: ${loginError}`, { variant: "error" });
      setTimeout(() => {
        this.props.clearAllLoginMessages();
      }, 20000);
    }
  }

  handleSubmit(event, values) {
    this.props.checkLogin(
      { userName: this.state.userName, password: this.state.password },
      this.props.history
    );
  }

  handleError = () => {
    setTimeout(() => {
      this.props.clearStore();
    }, 5000);
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleVerifyTotp = (totp) => {
    this.props.verifyTotpRequest(this.props.mfaSetup.session, totp);
    console.log("OTP verified API Called!"); // You can redirect or perform other actions
  };

  handleLoginClick = () => {
    this.setState({ step: 1 });
  };

  handleLoginWithTotp = (totp) => {
    this.props.checkLogin(
      {
        userName: this.state.userName,
        password: this.state.password,
        mfaCode: totp,
      },
      this.props.history
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={`${classes.root}`}>
        <div className={`${classes.background}`}></div>
        <SnackbarProvider />
        <Container>
          <div>
            <Slide
              direction={this.state.step === 1 ? "left" : "right"}
              in={this.state.step === 1}
              mountOnEnter
              unmountOnExit
              timeout={500}
            >
              {/* Login component */}
              <Grid container component="main" className={classes.container}>
                <Grid item xs={12} sm={8} md={5} lg={4}>
                  <div className={classes.form}>
                    <div className={classes.logo}>
                      <Link to="/" className="logo">
                        <img src={logodark} height="60" alt="logo" />
                      </Link>
                    </div>
                    <Typography component="h1" variant="h5" align="center">
                      Sign in to continue to CloudCatcher.
                    </Typography>

                    <AvForm onValidSubmit={this.handleSubmit}>
                      <Box mt={4} textAlign="center">
                        <TextField
                          label="Email"
                          variant="outlined"
                          name="Email"
                          value={this.state.userName}
                          type="text"
                          id="username"
                          placeholder="Enter Email"
                          onChange={(e) =>
                            this.setState({ userName: e.target.value })
                          }
                          required
                          fullWidth
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter username",
                            },
                          }}
                        />
                      </Box>
                      <Box mt={4} textAlign="center">
                        <TextField
                          label="Password"
                          variant="outlined"
                          name="password"
                          value={this.state.password}
                          type={this.state.showPassword ? "text" : "password"}
                          id="userpassword"
                          placeholder="Enter password"
                          fullWidth
                          onChange={(e) =>
                            this.setState({ password: e.target.value })
                          }
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter a password",
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={this.handleClickShowPassword}
                                  onMouseDown={this.handleMouseDownPassword}
                                >
                                  {this.state.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>

                      <Box mt={2} textAlign="center">
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          type="submit"
                        >
                          {this.props.loading && (
                            <div
                              style={{
                                margin: "20px",
                                display: "flex",
                                direction: "row",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                justifyItems: "center",
                              }}
                            >
                              <img src={spinner} width="24px" alt="Spinner" />
                            </div>
                          )}
                          Log In
                        </Button>
                      </Box>

                      <Box mt={2} textAlign="center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock mr-1"></i> Forgot your
                          password?
                        </Link>
                      </Box>
                    </AvForm>
                    <Box mt={2} textAlign="center">
                      <Typography variant="body2">
                        Don't have an account ?{" "}
                        <a
                          href={
                            process.env.REACT_APP_CLOUDNOVA_DEMO_REQUEST_FORM
                          }
                          className="font-weight-medium text-primary"
                        >
                          Schedule a Demo
                        </a>
                      </Typography>
                    </Box>
                  </div>
                </Grid>
              </Grid>

              {/* <form onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
                    <Button type="submit">Login</Button>
                  </form> */}
            </Slide>

            <Slide
              direction={this.state.step === 2 ? "left" : "right"}
              in={this.state.step === 2}
              mountOnEnter
              unmountOnExit
              timeout={500}
            >
              <div
                style={{
                  padding: "40px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper style={{ padding: 20, textAlign: "center" }}>
                  <Typography variant="h5" gutterBottom>
                    MFA Set Up
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Please open the Duo Authenticator App and scan the QR code
                    below to setup your account!
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* QR code component */}
                    <div style={{ marginRight: 20 }}>
                      {/* <QrCode base64Image={qrCodeImage} /> */}
                      <QrCode base64Image={this.state.base64Image} />
                    </div>
                    {/* Verification code component */}
                    <div>
                      <Paper style={{ padding: 20, textAlign: "center" }}>
                        <Typography variant="h5" gutterBottom>
                          Verifiy OTP
                        </Typography>
                        <VerificationOtp
                          onVerify={this.handleVerifyTotp}
                          loading={this.props.verifyTotpLoading || this.props.loading || this.state.redirecting}
                        />
                      </Paper>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div>
                    <Typography>
                      If you don't have the app, please
                      <LinkButton
                        color="primary"
                        onClick={this.openAppDownloadModal}
                        className="font-weight-medium text-primary"
                      >
                        click here
                      </LinkButton>{" "}
                      and scan the QR code to download{" "}
                      <b>Duo Authenticator App</b>.
                    </Typography>
                    <Typography>OR</Typography>
                    <Typography>
                      download an authenticator app of your choice that supports{" "}
                      <a
                        className="font-weight-medium text-primary"
                        href="https://en.wikipedia.org/wiki/Time-based_one-time_password"
                        target="_blank"
                      >
                        <b>TOTP</b>
                      </a>
                      .
                    </Typography>
                  </div>

                  {this.props.verifyTotpSuccess && (
                    <Box
                      sx={{
                        // height: 100,
                        borderRadius: 4,
                        bgcolor: "#d2f8d2",
                      }}
                    >
                      <Typography variant="body" gutterBottom>
                        MFA Setup is successful. Kindly go to login page by
                        clicking
                        <LinkButton
                          color="primary"
                          onClick={this.handleLoginClick}
                        >
                          here.
                        </LinkButton>
                      </Typography>
                    </Box>
                  )}
                </Paper>
              </div>
            </Slide>

            <Slide
              direction={this.state.step === 3 ? "left" : "right"}
              in={this.state.step === 3}
              mountOnEnter
              unmountOnExit
              timeout={500}
            >
              <div
                style={{
                  padding: "40px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper style={{ padding: 20, textAlign: "center" }}>
                  <Typography variant="h5" gutterBottom>
                    MFA Verification
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <VerificationMfaCode
                      onVerify={this.handleLoginWithTotp}
                      loading={this.props.loading}
                      loginError={this.props.loginError}
                    />
                  </div>
                </Paper>
              </div>
            </Slide>
          </div>
        </Container>

        <Modal
          isOpen={this.state.appDownloadModalOpen}
          toggle={this.closeAppDownloadModal}
        >
          <ModalHeader toggle={this.closeAppDownloadModal}>
            QR Code for DUO App
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5" align="center">
                Scan this QR to download duo authenticator app.
              </Typography>
              <img src={duoQrCode} alt="Duo QR Code" width={200} />
            </div>
          </ModalBody>
        </Modal>

        <BackgroundPattern />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    loginError,
    loading,
    user,
    mfaSetup,
    verification,
    stage,
    verifyTotpLoading,
    verifyTotpSuccess,
    verifyTotpError,
  } = state.login;
  return {
    loginError,
    loading,
    user,
    mfaSetup,
    verification,
    stage,
    verifyTotpLoading,
    verifyTotpSuccess,
    verifyTotpError,
    orgLicensing: state.licensing,
  };
};

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, {
      checkLogin,
      clearStore,
      getLicensingData,
      apiError,
      verifyTotpRequest,
      clearAllLoginMessages,
    })(Login)
  )
);
