import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import SessionExpired from "../pages/Authentication/SessionExpired";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Register from "../pages/Authentication/Register";
import ResetPassWord from "../pages/Authentication/ResetPassword";

//Precheck page for register
import ValidateToken from "../pages/Authentication/ValidateToken";

// Static page for Private Policy.
import PrivatePolicy from "../pages/Authentication/PrivatePolicy";
import IACManagement from "../pages/IACManagement";

// Asset Management
import AssetExplorer from "../pages/AssetManagement/AssetManagement";

// Dashboard
import Settings from "../pages/Settings";
import Configuration from "../pages/Configuration";
import Compliance from "../pages/ComplianceReport/Compliance";
import CostInsightDashboard from "../pages/CostInsight";
import PairingAccountGuide from "../components/AccountDetailPolicy/components/PairingAccountGuide";
import EventInsight from "../pages/EventData/EventInsight";
import PasswordResetPage from "../pages/Authentication/PasswordResetPage";
import CostOptimizationDashboard from "../pages/CostOptimization";
import CostAndUsageReports from "../components/AccountDetailPolicy/components/CostAndUsageReports";
import RuleRemediation from "../components/RuleRemediation";
import Security from "../pages/Security/Security";
import EmailPage from "../pages/Support/Email/EmailPage";
import ReleaseNotesPage from "../pages/Support/ReleaseNotes/ReleaseNotesPage";
import FaqPage from "../pages/Support/FAQ/FaqPage";
import UserManualPage from "../pages/Support/UserManual/UserManualPage";
import FreeTrial from "../pages/FreeTrial";
import InaccessibleResources from "../pages/FreeTrial/InaccessibleResources";
import TrialExpiredPage from "../pages/FreeTrial/TrialExpiredPage";
import UserAuthentication from "../pages/UserAuthentication";
import SignupPage from "../pages/AwsMarketPlace/SignupPage";
import RegisterPage from "../pages/AwsMarketPlace/RegisterPage";
import BillingPage from "../pages/Billing";
import Dashboard from "../pages/Dashboard";
import { Rules } from "../pages/Rules";
import ApplicationsTagging from "../pages/CostInsight/ApplicationsTagging";
import SpendAllocation from "../pages/CostInsight/SpendCategory";
import OrgSelection from "../pages/OrgSelection";

const adminRoutesOnly = [
  { path: "/billing", component: BillingPage },
];

const authProtectedRoutes = [

  { path: "/configuration/*", component: Configuration, licenseCheck: true },
  {
    path: "/configuration/",
    component: () => <Redirect to="/configuration/accounts" />,
  },
  { path: "/security", component: Security },
  { path: "/eventinsight", component: EventInsight, licenseCheck: true },
  { path: "/Rules", component: Rules },
  { path: "/costinsight", component: CostInsightDashboard, licenseCheck: true },
  { path: "/costoptimization", component: CostOptimizationDashboard, licenseCheck: true },
  { path: "/compliance", component: Compliance},
  { path: "/assetinsight", component: AssetExplorer },
  { path: "/tagLabeling", component: ApplicationsTagging },
  { path: "/spendAllocation", component: SpendAllocation },

  // { path: "/dashboard", component: Dashboard }, Old Dashboard
  { path: "/dashboard", component: Dashboard, licenseCheck: true, partialView: true },

  { path: "/settings", component: Settings },

  { path: "/pairingaccountguide", component: PairingAccountGuide },
  { path: "/costandusagereports", component: CostAndUsageReports },

  { path: "/ruleremediation/:resourceName", component: RuleRemediation },

  { path: "/iacmanagement/*", component: IACManagement, licenseCheck: true },

  { path: "/supportrequest/", component: EmailPage },
  { path: "/releasenotes/", component: ReleaseNotesPage },
  { path: "/faq/", component: FaqPage },
  { path: "/user-manual/", component: UserManualPage },
  { path: "/inaccessibleResources", component: InaccessibleResources },

  { path: "/orgSelection", component: OrgSelection },

  {
    path: "/iacmanagement/",
    component: () => <Redirect to="/iacmanagement/dashboard" />,
  },

  { path: "/trial-expired", component:  TrialExpiredPage},

  // this route should be at the end of all other routes
  { path: "/", component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/sessionExpired", component: SessionExpired },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password", component: PasswordResetPage },
  { path: "/internal/register", component: Register },
  { path: "/register", component: ValidateToken }, //If we want to disable self-onboarding
  { path: "/privatepolicy", component: PrivatePolicy },
  { path: "/resetPassword", component: ResetPassWord },
  { path: "/freeTrial", component: FreeTrial },
  { path: "/authenticateUser", component: UserAuthentication },
  { path: "/aws-marketplace/signup", component: SignupPage },
  { path: "/aws-marketplace/onboard", component: RegisterPage },
];

export { authProtectedRoutes, publicRoutes, adminRoutesOnly };
