import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchOrgCodes,
  clearOrgCodesState,
} from "../../store/auth/getOrgScope/orgScopeSlice";
import {
  CircularProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { OrgContext } from "../../OrgContext";

const OrgSelection = () => {
  const dispatch = useDispatch();
  const history = useHistory(); // Initialize history for navigation
  const { isLoading, orgCodes, errorMessage } = useSelector(
    (state) => state.orgScope
  );

  const { setHasSelectedOrganization } = useContext(OrgContext);

  console.log("In Org Selection Component");

  useEffect(() => {
    setHasSelectedOrganization(false);
    dispatch(fetchOrgCodes());
    return () => {
      dispatch(clearOrgCodesState());
    };
  }, [dispatch]);

  const handleSnackbarClose = () => {
    dispatch(clearOrgCodesState());
  };

  const handleOrganizationClick = (org) => {
    localStorage.setItem("selectedOrganization", JSON.stringify(org));
    localStorage.setItem("hasSelectedOrganization", true);
    setHasSelectedOrganization(true); // Update context state
    history.push("/dashboard");
  };

  return (
    <div className="page-content" style={{ padding: "20px" }}>
      <h2>Organization Selection</h2>

      {isLoading ? (
        <div style={{ textAlign: "center", margin: "20px" }}>
          <CircularProgress />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Organization Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(orgCodes) && orgCodes.length > 0 ? (
                orgCodes.map((org) => (
                  <TableRow key={org.orgCode}>
                    <TableCell>{org.orgName}</TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleOrganizationClick(org)}
                      >
                        <ArrowForwardIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} style={{ textAlign: "center" }}>
                    No organizations available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={errorMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </div>
  );
};

export default OrgSelection;
