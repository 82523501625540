import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";

import GetKibanaDashboard from "../../components/Common/HelperComponents/KibanaDashboardComponents/GetKibanaDashboard";

const Compliance = () => {
  const [activeTab, setActiveTab] = useState("compliance");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "compliance":
        return <GetKibanaDashboard dashboardKey={"Compliance"} />;
      case "trend":
        return <GetKibanaDashboard dashboardKey={"Compliance_Trend_Dashboard"} />;
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Compliance" value="compliance" />
        <Tab label="Trend Analyzer" value="trend" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default Compliance;
