import React, { createContext, useState, useEffect } from "react";

export const OrgContext = createContext();

export const OrgProvider = ({ children }) => {
  const [hasSelectedOrganization, setHasSelectedOrganization] = useState(
    localStorage.getItem("hasSelectedOrganization") === "true"
  );

  // Update localStorage when state changes
  useEffect(() => {
    localStorage.setItem("hasSelectedOrganization", hasSelectedOrganization);
  }, [hasSelectedOrganization]);

  // Listen for changes to localStorage (for cross-tab updates)
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "hasSelectedOrganization") {
        setHasSelectedOrganization(event.newValue === "true");
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <OrgContext.Provider value={{ hasSelectedOrganization, setHasSelectedOrganization }}>
      {children}
    </OrgContext.Provider>
  );
};
