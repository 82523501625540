import { createSlice } from "@reduxjs/toolkit";

export const orgScopeSlice = createSlice({
  name: "orgScope",
  initialState: {
    isLoading: false,
    orgCodes: [],
    errorMessage: null,
  },
  reducers: {
    fetchOrgCodes: (state) => {
      state.isLoading = true;
      state.orgCodes = [];
      state.errorMessage = null;
    },
    fetchOrgCodesSuccess: (state, action) => {
      console.log("🚀 ~ action fetchOrgCodesSuccess:", action);
      state.isLoading = false;
      state.orgCodes = action.payload;
      state.errorMessage = null;
    },
    fetchOrgCodesFailure: (state, action) => {
      state.isLoading = false;
      state.orgCodes = [];
      state.errorMessage = action.payload.message;
    },
    clearOrgCodesState: (state) => {
      state.isLoading = false;
      state.orgCodes = [];
      state.errorMessage = null;
    },
  },
});

export const {
  fetchOrgCodes,
  fetchOrgCodesSuccess,
  fetchOrgCodesFailure,
  clearOrgCodesState,
} = orgScopeSlice.actions;

export default orgScopeSlice.reducer;
